import styles from './refundPolicy.module.css';
import { Card } from "../card/Card";
import { SITE_NAME } from '../../helpers/constants';

export const RefundPolicy = () => {
    return (
        <Card centered={true} classes={[styles.refund_policy_card]} cardHeader={<h1>Refund Policy</h1>}>
            <div>
                <h3><strong>Refund</strong></h3>
                <p>We do not process any refunds once the plan gets activated on your account, however under certain circumstances, we may allow you to change your plan if required, by manual intervention.</p>
            </div>
            <div>
                <h3><strong>Cancellation</strong></h3>
                <p>We do not have any cancellation policy as of now.</p>
            </div>
            <div>
                <p>Regards,<br />{SITE_NAME} Team</p>
            </div>
        </Card>
    );
};