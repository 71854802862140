import { BASE_URL, SITE_NAME, SITE_TAGLINE, META_DESCRIPTION } from "./constants";

/**
 * fetch API wrapper
 */
export const fetchWrapper = {
    get,
    post,
    put,
    delete: _delete
};

async function get(endpoint) {
    const url = `${BASE_URL}${endpoint}`;
    const requestOptions = {
        method: 'GET',
        headers: getAuthHeader()
    };
    return fetch(url, requestOptions).then(handleResponse);
}

async function post(endpoint, body) {
    const url = `${BASE_URL}${endpoint}`;
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', ...getAuthHeader() },
        body: JSON.stringify(body)
    };
    return fetch(url, requestOptions).then(handleResponse);
}

async function put(endpoint, body) {
    const url = `${BASE_URL}${endpoint}`;
    const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', ...getAuthHeader() },
        body: JSON.stringify(body)
    };
    return fetch(url, requestOptions).then(handleResponse);
}

// prefixed with underscored because delete is a reserved word in javascript
async function _delete(endpoint) {
    const url = `${BASE_URL}${endpoint}`;
    const requestOptions = {
        method: 'DELETE',
        headers: getAuthHeader()
    };
    return fetch(url, requestOptions).then(handleResponse);
}

// helper async functions
async function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            const error = data || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}

// Make API call
export async function makeAPICall({ type, endpoint, payload = {}, dispatch, setIsLoading }) {
    // Checks
    if (!type || !endpoint || !dispatch || !setIsLoading) {
        return;
    }

    // Enable loader
    dispatch(setIsLoading(true));

    let response = null;
    let error = null;
    try {
        response = await fetchWrapper[type](endpoint, payload);
    } catch (err) {
        if (err.errCode) {
            error = err;
            response = err;
        } else {
            error = err;
            console.error(err);
        }
    } finally {
        // Disable loader
        dispatch(setIsLoading(false));

        // If trial & subscription already expired
        if (response.errCode && response.errCode === 'ERR401-EX-SUBSCRIPTION') {
            window.location.href = BASE_URL + '/buy-subscription-plan';
            return;
        }

        // Return response
        return { response, error };
    }
}

// Get auth header
function getAuthHeader() {
    const userInfo = localStorage.getItem('__userInfo') || null;
    let accessToken = null;
    try {
        accessToken = JSON.parse(userInfo).accessToken;
    } catch (error) {
        console.log(error.message);
    }
    return accessToken ?
        { Authorization: `Bearer ${accessToken}` } : {};
}

/**
 * Check if email is valid
 * @param {string} emailAddress 
 * @returns 
 */
export const isValidEmailAddress = (emailAddress) => {
    const pattern = /^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
    return pattern.test(emailAddress);
}

/**
 * It should be 10 digits long.
 * The first digit should be a number [6 - 9].
 * The rest 9 digits should be any number[0 - 9].
 * It can have 11 digits including 0 at the starting.
 * It can have 12 digits including 91 at the starting.
 * Examples: 9876543210, 09876543210, 919876543210
 * @param {number} mobileNumber 
 * @returns boolean
 */
export const isValidIndianMobileNumber = (mobileNumber) => {
    const pattern = /^(0|91)?[6-9][0-9]{9}$/;
    return pattern.test(mobileNumber);
}

// Optimize for SEO
export function doOptimizationForSEO(path) {
    // Checks
    path = path.toLowerCase().includes('/view-receipt') ? '/view-receipt' : path.toLowerCase();

    // Initialize
    const data = {
        title: `${SITE_NAME} - ${SITE_TAGLINE}`,
        metaDescription: META_DESCRIPTION
    };

    switch (path) {
        case '/profile':
            data.title = `Profile | ${data.title}`;
            data.metaDescription = `Manage your profile | ${data.metaDescription}`;
            break;
        case '/my-business':
            data.title = `My Business | ${data.title}`;
            data.metaDescription = `Manage your business | ${data.metaDescription}`;
            break;
        case '/my-items':
            data.title = `My Items | ${data.title}`;
            data.metaDescription = `Manage your items | ${data.metaDescription}`;
            break;
        case '/create-bills':
            data.title = `Create Bills | ${data.title}`;
            data.metaDescription = `Create bills | ${data.metaDescription}`;
            break;
        case '/guide':
            data.title = `Guide | ${data.title}`;
            data.metaDescription = `Guide to create bills on ${SITE_NAME} | ${data.metaDescription}`;
            break;
        case '/contact-us':
            data.title = `Contact Us | ${data.title}`;
            data.metaDescription = `Contact Us | ${data.metaDescription}`;
            break;
        case '/about-us':
            data.title = `About Us | ${data.title}`;
            data.metaDescription = `About Us | ${data.metaDescription}`;
            break;
        case '/faq':
            data.title = `FAQ | ${data.title}`;
            data.metaDescription = `Frequently Asked Questions | ${data.metaDescription}`;
            break;
        case '/privacy-policy':
            data.title = `Privacy Policy | ${data.title}`;
            data.metaDescription = `Privacy Policy | ${data.metaDescription}`;
            break;
        case '/signup':
            data.title = `Create A New Account | ${data.title}`;
            data.metaDescription = `Create a new account | ${data.metaDescription}`;
            break;
        case '/login':
            data.title = `Login To Your Account | ${data.title}`;
            data.metaDescription = `Login to your account | ${data.metaDescription}`;
            break;
        case '/reset-password':
            data.title = `Reset Your Password | ${data.title}`;
            data.metaDescription = `Reset your password | ${data.metaDescription}`;
            break;
        case '/view-receipt':
            data.title = `View & Download Your Receipt | ${data.title}`;
            data.metaDescription = `View & download your receipt | ${data.metaDescription}`;
            break;
        case '/reports':
            data.title = `View Reports | ${data.title}`;
            data.metaDescription = `View & download various reports | ${data.metaDescription}`;
            break;

        default:
            break;
    }


    document.title = data.title;
    document.querySelector('meta[property="og:title"]').setAttribute('content', data.title);
    document.querySelector('meta[name="twitter:title"]').setAttribute('content', data.title);

    document.querySelector('meta[name="description"]').setAttribute('content', data.metaDescription);
    document.querySelector('meta[property="og:description"]').setAttribute('content', data.metaDescription);
    document.querySelector('meta[name="twitter:description"]').setAttribute('content', data.metaDescription);
}

// Get ISO formatted datetime
export const getISOFormattedDateTime = (dateObj) => {
    dateObj.setMinutes(dateObj.getMinutes() - dateObj.getTimezoneOffset());
    return dateObj.toISOString().slice(0, 16);
};

// Detect if touch device
export const isTouchDevice = () => {
    return 'ontouchstart' in window || window.navigator.maxTouchPoints > 0;
};

// Open link in new tab
export const openInNewTab = (url) => {
    window.open(url, '_blank').focus();
}