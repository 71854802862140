import styles from "./home.module.css";
import { Button } from "../forms/button/Button";
import { getDownloadIcon } from "../../helpers/iconUtils";
import { deferredPrompt } from "../header/Header";
import toast from 'react-hot-toast';
import { Link } from "react-router-dom";
import { isTouchDevice, openInNewTab } from "../../helpers/utils";

export const Home = () => {

    // Handle PWA installation
    const handlePWAInstallation = (deferredPrompt) => {
        // If touch device, open playstore
        if (isTouchDevice()) {
            openInNewTab('https://play.google.com/store/apps/details?id=in.billguru.twa');
            return;
        }

        if (!deferredPrompt) {
            toast.success('Either App is already installed or it is not supported on your device.');
            return;
        }

        // Show prompt to install app
        deferredPrompt.prompt()
    };

    return (
        <div className={styles.main_container}>
            {/* Primary contents */}
            <div className={styles.primary_contents}>
                {/* Left container */}
                <div className={styles.left_container}>
                    <h1>BillGuru : Your Ultimate Billing Solution</h1>
                    <p>Are you tired of the hassle that comes with managing your business's finances? Say goodbye to billing headaches with BillGuru i.e. Bill Guru.</p>
                    <Button
                        type="danger"
                        text={<>Free Download {getDownloadIcon(32, 32)}</>}
                        onClick={() => handlePWAInstallation(deferredPrompt)}
                    />
                </div>

                {/* Right container */}
                <div className={styles.right_container}>
                    <img
                        src="/billguru_landing_page_banner.png"
                        alt="Software demo image"
                        loading="lazy"
                    />
                </div>
            </div>

            {/* Secondary contents */}
            <div className={styles.secondary_contents}>
                <h2>The Best Billing Software In India</h2>
                <p>If you are looking for the best billing software in India, look no further than BillGuru, the one and only solution to make easy bills whether you have a retail, wholesale or restaurant business, this software works amazing.</p>

                <h3>Why to Choose BillGuru?</h3>
                <p className={styles.italic}>Here are just a few of the benefits of using BillGuru:</p>
                <ul>
                    <li>Save time and money by automating your billing and invoicing.</li>
                    <li>Create and send professional invoices in minutes.</li>
                    <li>Easy to use, even if you're not tech-savvy.</li>
                    <li>Your <strong>free trial for 30 days</strong> gets activated right away once you register.</li>
                    <li>After free trial, our subscription plan starts at just <strong>&#8377; 99/month</strong>.</li>
                    <li>We also have a <Link to='/guide'>Guide</Link> that will explain you how to use our software efficiently.</li>
                </ul>
            </div>
        </div>
    );
};