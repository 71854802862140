import styles from './selectBox.module.css';

export const SelectBox = ({ label, name, options, value, onChange, required, errors }) => {
    return (
        <div className={styles.form_group}>
            {label && <label htmlFor='select-box'>{!!required ? label : `${label} (Optional)`}</label>}
            <select
                name={name}
                required={!!required}
                onChange={onChange}
                value={value}
            >
                {options && options.map(option => {
                    return <option key={option.id} value={option.key}>{option.value}</option>
                })}
            </select>
            {errors && errors[name] && <span className={styles.error}>{errors[name]}</span>}
        </div>
    );
};